<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="info-training"
      typeCss="active-full"
      @setActive="setActive"
    />

    <div class="page-container">
      <validation-observer ref="tabAccount">
        <info-training
          v-if="isRendered('info-training')"
          :dataInfo="dataInfo"
          class="tab-pane"
          :class="{'active':activeTab==='info-training'}"
        />
        <table-group
          v-if="isRendered('group-three')"
          :modeType="'NH03'"
          :dataInfo="dataSelfTrainingId"
          class="tab-pane"
          :class="{'active':activeTab==='group-three'}"
        />
      </validation-observer>
      <div
        class="mt-2"
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="hadnleCreate($event)"
        >
          Lưu lại
        </b-button>
        <b-button
          variant="outline-primary"
          type="reset"
          @click="backUser"
        >
          Quay lại
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
}
  from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoTraining from './components/InfoTraining.vue'
import TableGroup from './components/TableGroup.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    MyTabs,
    ValidationObserver,
    ValidationProvider,
    InfoTraining,
    BButton,
    TableGroup,
  },
  data() {
    return {
      required,
      activeTab: null,
      listTabs: [
        {
          key: 'info-training',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'group-three',
          title: 'Nhóm 3',
          icon: 'UsersIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      dataInfo: {
        code: '',
        dateTraining: '',
        workerId: '',
        cost: '',
      },
      selfId: null,
      dataSelfTrainingId: null,
    }
  },
  watch: {
    dataSelfTrainingId(val) {
      if (val) {
        this.dataInfo = {
          code: this.dataSelfTrainingId.code,
          dateTraining: this.dataSelfTrainingId.dateTraining,
          workerId: this.dataSelfTrainingId.workerId,
          cost: this.dataSelfTrainingId.cost,
        }
      }
    },
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.selfId = this.$route.params.id
      this.listTabs[1].isDisabled = false
      this.fetchSelfTrainingId(this.selfId)
    }

    if (this.activeTab === null) {
      this.activeTab = 'info-training'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },
    // Lấy thông tin khai báo tai nạn lao động theo id
    async fetchSelfTrainingId() {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_PERFORM_TRAINING}${this.selfId}`)
      this.dataSelfTrainingId = data
    },

    // Trở về danh sách
    backUser() {
      this.$router.push({ name: 'self-training' })
    },

    // Action tạo khai báo tai nạn lao động
    async hadnleCreate(bvModalEvt) {
      if (this.$route.params.id) {
        const data = {
          ...this.dataInfo,
          id: this.$route.params.id,
          cost: parseInt(this.dataInfo.cost, 10),
          isSelfTraining: true,
        }
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.EDIT_PERFORM_TRAINING, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Cập nhật thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'self-training' })
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      } else {
        const data = {
          ...this.dataInfo,
          cost: parseInt(this.dataInfo.cost, 10),
          isSelfTraining: true,
        }
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.CREATE_PERFORM_TRAINING, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Tạo thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'self-training-edit', params: { id: res.data?.data?.id } })
                this.listTabs[1].isDisabled = false
                this.selfId = res.data?.data?.id
                this.fetchSelfTrainingId(this.selfId)
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      }
    },
  },
}
</script>
