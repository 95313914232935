var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"performTrainingId"}},[_c('b-form',{staticClass:"content-form"},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"company"}},[_c('label',{attrs:{"label-for":"basicInput"}},[_vm._v("Mã đợt huấn luyện "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.customCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.code),callback:function ($$v) {_vm.$set(_vm.dataInfo, "code", $$v)},expression:"dataInfo.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"company"}},[_c('label',{attrs:{"label-for":"basicInput"}},[_vm._v("Ngày huấn luyện "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"date","rules":"required","custom-messages":_vm.customdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{class:{'is-invalid':errors.length > 0 },attrs:{"disabledInput":true},model:{value:(_vm.dataInfo.dateTraining),callback:function ($$v) {_vm.$set(_vm.dataInfo, "dateTraining", $$v)},expression:"dataInfo.dateTraining"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"company"}},[_c('label',{attrs:{"label-for":"basicInput"}},[_vm._v("Người huấn luyện "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.customMan},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"state":errors.length > 0 ? false:null,"label":"name","options":_vm.dataAllWorker || [],"placeholder":"Lựa chọn người huấn luyện"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInfo.workerId),callback:function ($$v) {_vm.$set(_vm.dataInfo, "workerId", $$v)},expression:"dataInfo.workerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"company"}},[_c('label',{attrs:{"label-for":"basicInput"}},[_vm._v("Tổng chi phí "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.customCost},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.cost),callback:function ($$v) {_vm.$set(_vm.dataInfo, "cost", $$v)},expression:"dataInfo.cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }