<template>
  <div id="performTrainingId">
    <b-form class="content-form">
      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Mã đợt huấn luyện <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customCode"
            >
              <b-form-input
                id="basicInput"
                v-model="dataInfo.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Ngày huấn luyện <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="date"
              rules="required"
              :custom-messages="customdate"
            >
              <date-time-picker
                v-model="dataInfo.dateTraining"
                :disabledInput="true"
                :class="{'is-invalid':errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Người huấn luyện <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customMan"
            >
              <v-select
                v-model="dataInfo.workerId"
                :reduce="item => item.id"
                :state="errors.length > 0 ? false:null"
                label="name"
                :options="dataAllWorker || []"
                placeholder="Lựa chọn người huấn luyện"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Tổng chi phí <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customCost"
            >
              <b-form-input
                id="basicInput"
                v-model="dataInfo.cost"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    DateTimePicker,
    BFormCheckbox,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  props: {
    dataInfo: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      customCode: {
        required: 'Mã đợt huấn luyện là bắt buộc',
      },
      customdate: {
        required: 'Ngày huấn luyện là bắt buộc',
      },
      customMan: {
        required: 'Người huán luyện là bắt buộc',
      },
      customBusiness: {
        required: 'Doanh nghiệp được huấn luyện là bắt buộc',
      },
      customCost: {
        required: 'Tổng chi phí là bắt buộc',
      },
    }
  },
  computed: {
    ...mapGetters('workerPermit', ['dataAllWorker']),
    ...mapGetters('performTraining', ['dataAllBusiness']),
  },
  mounted() {
    this.fetchDataComboboxAllWorker()
    this.fetchDataComboboxAllBusiness()
  },
  methods: {
    ...mapActions('workerPermit', ['fetchDataComboboxAllWorker']),
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),

  },
}
</script>

<style lang="scss">
.checkbox-accident {
  .custom-checkbox {
    margin-top: 1rem;
  }
}
</style>
