export default {
  LIST_COMBOBOX_BUSINESS: 'Bussiness/get-combobox-bussiness',
  LIST_SELF_TRAINING: 'Training/self-trainings',
  CREATE_PERFORM_TRAINING: 'Training/add-training',
  EDIT_PERFORM_TRAINING: 'Training/update-training',
  DETAIL_PERFORM_TRAINING: 'Training/training/',
  DELETE_PERFROM_TRAINING: 'Training/delete-training',
  TARGET_TRAINING_CODE: 'TargetTraining/get-target-training-by-code',
  LIST_TRAINING_WORKERS: 'Training/list-training-worker',
  DELETE_TRAINING_WORKERS: 'Training/delete-training-worker',
  CREATE_TRAINING_WORKERS: 'Training/add-training-worker',
  LIST_NOT_TRAINING_WORKERS: 'Training/get-combobox-worker-not-training',
}
